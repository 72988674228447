<template>
  <div class="p-6 bg-white rounded shadow">
    <h3 class="mb-4 text-2xl font-bold uppercase">Privacy Policy</h3>
    <p class="mb-2 leading-6">Updated at 2021-10-23</p>
    <p class="mb-4 leading-6">
      HuskyTask (“we,” “our,” or “us”) is committed to protecting your privacy.
      This Privacy Policy explains how your personal information is collected,
      used, and disclosed by HuskyTask . This Privacy Policy applies to our
      website, and its associated subdomains (collectively, our “Service”)
      alongside our application, HuskyTask. By accessing or using our Service,
      you signify that you have read, understood, and agree to our collection,
      storage, use, and disclosure of your personal information as described in
      this Privacy Policy and our Terms of Service.
    </p>
    <h3 class="mb-4 text-2xl font-bold uppercase">Definitions and key terms</h3>
    <p class="mb-4 leading-6">
      To help explain things as clearly as possible in this Privacy Policy,
      every time any of these terms are referenced, are strictly defined as:
    </p>
    <div class="grid grid-cols-1 my-5 md:gap-7 md:grid-cols-2">
      <ul class="ml-4 list-disc">
        <li>
          Cookie: small amount of data generated by a website and saved by your
          web browser. It is used to identify your browser, provide analytics,
          remember information about you such as your language preference or
          login information.
        </li>
        <li>
          Company: when this policy mentions “Company,” “we,” “us,” or “our,” it
          refers to Rageh Group Trading, 656 Bedford Highway that is responsible
          for your information under this Privacy Policy.
        </li>
        <li>
          Country: where HuskyTask or the owners/founders of HuskyTask are
          based, in this case is Canada Customer: refers to the company,
          organization or person that signs up to use the HuskyTask Service to
          manage the relationships with your consumers or service users.
        </li>
        <li>
          Device: any internet connected device such as a phone, tablet,
          computer or any other device that can be used to visit HuskyTask and
          use the services.
        </li>
        <li>
          IP address: Every device connected to the Internet is assigned a
          number known as an Internet protocol (IP) address. These numbers are
          usually assigned in geographic blocks. An IP address can often be used
          to identify the location from which a device is connecting to the
          Internet.
        </li>
      </ul>
      <ul class="ml-4 list-disc">
        <li>
          Personnel: refers to those individuals who are employed by HuskyTask
          or are under contract to perform a service on behalf of one of the
          parties.
        </li>
        <li>
          Personal Data: any information that directly, indirectly, or in
          connection with other information — including a personal
          identification number — allows for the identification or
          identifiability of a natural person.
        </li>
        <li>
          Service: refers to the service provided by HuskyTask as described in
          the relative terms (if available) and on this platform.
        </li>
        <li>
          Third-party service: refers to advertisers, contest sponsors,
          promotional and marketing partners, and others who provide our content
          or whose products or services we think may interest you.
        </li>
        <li>
          Website: HuskyTask 's site, which can be accessed via this URL:
          HuskyTask.ca You: a person or entity that is registered with HuskyTask to use the Services.
        </li>
      </ul>
    </div>
    <h3 class="mb-4 text-2xl font-bold uppercase">
      What Information Do We Collect?
    </h3>
    <p class="mb-4 leading-6">
      We collect information from you when you visit our website/app, register
      on our site, place an order, subscribe to our newsletter, respond to a
      survey or fill out a form.
    </p>
    <ul class="grid grid-cols-2 mb-5 ml-4 list-disc md:grid-cols-4">
      <li>Name / Username</li>
      <li>Phone Numbers</li>
      <li>Email</li>
      <li>Addresses</li>
      <li>Mailing Addresses</li>
      <li>Job Titles</li>
      <li>Debit/credit card numbers</li>
      <li>Password</li>
    </ul>

    <p class="mb-4 leading-6">
      We also collect information from mobile devices for a better user
      experience, although these features are completely optional:
    </p>
    <p class="mb-4 leading-6">
      Location (GPS): Location data helps to create an accurate representation
      of your interests, and this can be used to bring more targeted and
      relevant ads to potential customers.
    </p>
    <p class="mb-4 leading-6">
      Camera (Pictures): Granting camera permission allows the user to upload
      any picture straight from the website/app, you can safely deny camera
      permissions for this website/app.
    </p>
    <p class="mb-4 leading-6">
      Photo Gallery (Pictures): Granting photo gallery access allows the user to
      upload any picture from their photo gallery, you can safely deny photo
      gallery access for this website/app.
    </p>

    <h3 class="mb-4 text-2xl font-bold uppercase">
      How Do We Use The Information We Collect?
    </h3>
    <p class="mb-4 leading-6">
      Any of the information we collect from you may be used in one of the
      following ways:
    </p>
    <ul class="mb-4 ml-4 leading-6 list-disc">
      <li>
        To personalize your experience (your information helps us to better
        respond to your individual needs)
      </li>
      <li>
        To improve our website/app (we continually strive to improve our
        website/app offerings based on the information and feedback we receive
        from you)
      </li>
      <li>
        To improve customer service (your information helps us to more
        effectively respond to your customer service requests and support needs)
      </li>
      <li>To process transactions</li>
      <li>To administer a contest, promotion, survey or other site feature</li>
      <li>To send periodic emails</li>
    </ul>

    <h3 class="mb-4 text-2xl font-bold uppercase">
      When does HuskyTask use end user information from third parties?
    </h3>
    <p class="mb-4 leading-6">
      HuskyTask will collect End User Data necessary to provide the HuskyTask
      services to our customers. End users may voluntarily provide us with
      information they have made available on social media websites. If you
      provide us with any such information, we may collect publicly available
      information from the social media websites you have indicated. You can
      control how much of your information social media websites make public by
      visiting these websites and changing your privacy settings.
    </p>

    <h3 class="mb-4 text-2xl font-bold uppercase">
      When does HuskyTask use customer information from third parties?
    </h3>
    <p class="mb-4 leading-6">
      We receive some information from the third parties when you contact us.
      For example, when you submit your email address to us to show interest in
      becoming a HuskyTask customer, we receive information from a third party
      that provides automated fraud detection services to HuskyTask. We also
      occasionally collect information that is made publicly available on social
      media websites. You can control how much of your information social media
      websites make public by visiting these websites and changing your privacy
      settings.
    </p>

    <h3 class="mb-4 text-2xl font-bold uppercase">
      Do we share the information we collect with third parties?
    </h3>
    <p class="mb-4 leading-6">
      We may share the information that we collect, both personal and
      non-personal, with third parties such as advertisers, contest sponsors,
      promotional and marketing partners, and others who provide our content or
      whose products or services we think may interest you. We may also share it
      with our current and future affiliated companies and business partners,
      and if we are involved in a merger, asset sale or other business
      reorganization, we may also share or transfer your personal and
      non-personal information to our successors-in-interest.
    </p>

    <p class="mb-4 leading-6">
      We may engage trusted third party service providers to perform functions
      and provide services to us, such as hosting and maintaining our servers
      and the website/app, database storage and management, e-mail management,
      storage marketing, credit card processing, customer service and fulfilling
      orders for products and services you may purchase through the website/app.
      We will likely share your personal information, and possibly some
      non-personal information, with these third parties to enable them to
      perform these services for us and for you.
    </p>

    <p class="mb-4 leading-6">
      We may share portions of our log file data, including IP addresses, for
      analytics purposes with third parties such as web analytics partners,
      application developers, and ad networks. If your IP address is shared, it
      may be used to estimate general location and other technographics such as
      connection speed, whether you have visited the website/app in a shared
      location, and type of the device used to visit the website/app. They may
      aggregate information about our advertising and what you see on the
      website/app and then provide auditing, research and reporting for us and
      our advertisers. We may also disclose personal and non-personal
      information about you to government or law enforcement officials or
      private parties as we, in our sole discretion, believe necessary or
      appropriate in order to respond to claims, legal process (including
      subpoenas), to protect our rights and interests or those of a third party,
      the safety of the public or any person, to prevent or stop any illegal,
      unethical, or legally actionable activity, or to otherwise comply with
      applicable court orders, laws, rules and regulations.
    </p>

    <h3 class="mb-4 text-2xl font-bold uppercase">
      Where and when is information collected from customers and end users?
    </h3>
    <p class="mb-4 leading-6">
      HuskyTask will collect personal information that you submit to us. We may
      also receive personal information about you from third parties as
      described above.
    </p>

    <h3 class="mb-4 text-2xl font-bold uppercase">
      How Do We Use Your Email Address?
    </h3>
    <p class="mb-4 leading-6">
      By submitting your email address on this website/app, you agree to receive
      emails from us. You can cancel your participation in any of these email
      lists at any time by clicking on the opt-out link or other unsubscribe
      option that is included in the respective email. We only send emails to
      people who have authorized us to contact them, either directly, or through
      a third party. We do not send unsolicited commercial emails, because we
      hate spam as much as you do. By submitting your email address, you also
      agree to allow us to use your email address for customer audience
      targeting on sites like Facebook, where we display custom advertising to
      specific people who have opted-in to receive communications from us. Email
      addresses submitted only through the order processing page will be used
      for the sole purpose of sending you information and updates pertaining to
      your order. If, however, you have provided the same email to us through
      another method, we may use it for any of the purposes stated in this
      Policy. Note: If at any time you would like to unsubscribe from receiving
      future emails, we include detailed unsubscribe instructions at the bottom
      of each email.
    </p>

    <h3 class="mb-4 text-2xl font-bold uppercase">
      California Online Privacy Protection Act (CalOPPA)
    </h3>
    <p class="mb-4 leading-6">
      CalOPPA requires us to disclose categories of Personal Information we
      collect and how we use it, the categories of sources from whom we collect
      Personal Information, and the third parties with whom we share it, which
      we have explained above.
    </p>
    <p class="mb-4 leading-6">CalOPPA users have the following rights:</p>

    <ul class="mb-4 ml-4 leading-6 list-disc">
      <li>
        Right to Know and Access. You may submit a verifiable request for
        information regarding the: (1) categories of Personal Information we
        collect, use, or share; (2) purposes for
      </li>
      <li>
        which categories of Personal Information are collected or used by us;
        (3) categories of sources from which we collect Personal Information;
        and (4) specific pieces of Personal
      </li>
      <li>Information we have collected about you.</li>
      <li>
        Right to Equal Service. We will not discriminate against you if you
        exercise your privacy rights.
      </li>
      <li>
        Right to Delete. You may submit a verifiable request to close your
        account and we will delete Personal Information about you that we have
        collected.
      </li>
      <li>
        Right to request that a business that sells a consumer's personal data,
        not sell the consumer's personal data.
      </li>
    </ul>
    <h3 class="mb-4 text-2xl font-bold uppercase">
      California Online Privacy Protection Act (CalOPPA)
    </h3>
    <p class="mb-4 leading-6">
      Don't hesitate to contact us if you have any questions.
    </p>

    <ul class="mb-4 ml-4 leading-6 list-disc">
      <li>Via Email: <a href="mailto:contact@huskytask.ca">contact@huskytask.ca</a></li>
    </ul>
  </div>
</template>
<script>
export default {
  setup () {}
}
</script>
